.dashboard-container {
    width: 100%;
    display: flex;
    height: 100vh;
}

.content {
    flex: 7;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    background-color: #fff;
}

.topbar {
    margin: 0;
    height: 60px;
    background-color: #E8ECF5;
    color: #000;
}

.card{
    background-color: #fff;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.card p{
    font-size: 14px;
}

.card-icon{
    width: 50px;
    height: 50px;
    border-radius: 50px;
    border-radius: 50%;
    background-color: aliceblue;
    display: flex;
    justify-content: center;
    align-items: center;
}

.role{
    height: 10px;
    margin: auto 0;
    display: flex;
    align-items: center;
}

.title-1-cont{
    width: 100%;
    justify-content: space-between;
}

.users-btn-cont{
    display: flex;
}

.users-btn-cont button{
    background-color: #E8ECF5;
    color: #000;
    border: 1px solid gray;
    padding: 5px 10px;
    margin-right: 3px;
}

.users-btn-cont .active{
    background-color: rgb(216, 208, 208);
}