@tailwind base;
@tailwind components;
@tailwind utilities;

.d-none{
    display: none;
}

.text-yellow-gold{
    color: #FFC855;
}

.bg-yellow-gold{
    background-color: #FFC855;
}

.text-maroon-red{
    color: #931A1D;
}

.bg-maroon-red{
    background-color: #931A1D;
}

input,
textarea {
    color: rgb(75 85 99);
}

.main-container{
    position: relative;
    min-height: 100vh;
}

.main-children{
    min-height: 80vh;
}

.footer-container{
    /* position: ; */
    bottom: 0;
    right: 0;
    left: 0;
}

.mobile-pagination {
    display: none;
}

.pagination-margin {
    /* margin-top: -50px; */
}

@media (max-width: 438.5px) {
    .mobile-pagination {
        display: flex;
    }

    .big-pagination {
        display: none;
    }

}